import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Social from './Social';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <footer className="w-full -mt-2 text-white bg-black">
        <div className="flex flex-wrap items-center justify-between w-full mb-0 text-sm text-center font-display">
          <span className="p-4">
            © {new Date().getFullYear()} {data.site.siteMetadata.title}
          </span>
          <span className="p-4 md:w-auto">
            <a
              className="inline-flex px-1 text-green-700 hover:text-green-600 hover:no-underline"
              href="https://pd.design"
              target="_blank"
              rel="noopener noreferrer"
            >
              Designed and developed by PD
            </a>
          </span>
          <span className="w-full p-4 pb-0 mx-auto -order-1 md:order-0 md:mr-0 md:pb-4 md:w-auto">
            <Social />
          </span>
        </div>
      </footer>
    )}
  />
);

export default Footer;
