import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

const Header = () => (
  <StaticQuery
    query={graphql`
      query ContactQuery {
        site {
          siteMetadata {
            title
            email
            phone
            phoneFormatted
          }
        }
      }
    `}
    render={(data) => (
      <header className="w-full p-4 pb-2 bg-black font-display">
        <div className="flex flex-wrap items-center justify-between leading-none text-white border border-green-600">
          <div className="flex w-full md:w-1/2">
            <div className="flex items-center w-full px-6 pt-4 border-green-600 md:justify-center md:border-r md:py-4 md:w-auto">
              <div className="m-0 leading-none">
                <Link
                  className="text-2xl text-green-600 uppercase hover:no-underline"
                  to="/"
                >
                  {data.site.siteMetadata.title}{' '}
                </Link>
              </div>
            </div>
            <div className="flex-1 hidden px-6 py-4 text-xs leading-tight text-white uppercase border-r border-green-600 lg:block">
              <p className="mb-2">Minor panel &amp; paint expert</p>
              <p>
                4 Bolwarra Rd,{' '}
                <span className="text-green-600">Port&nbsp;Macquarie</span>
              </p>
            </div>
          </div>
          <nav className="flex justify-start flex-1 w-full px-6 py-2 my-2 md:justify-end md:w-1/2">
            <NavLink link="/#about" title="About" />
            <NavLink link="/#services" title="Services" />
            <NavLink link="/#contact" title="Contact" />
          </nav>
        </div>
      </header>
    )}
  />
);

export default Header;

const NavLink = ({ link, title }) => (
  <a
    href={link}
    className="mr-4 font-sans text-xs font-semibold text-white uppercase sm:font-display hover:no-underline md:mr-0 md:ml-4 lg:text-sm hover:text-green-600"
  >
    {title}
  </a>
);

NavLink.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
};
